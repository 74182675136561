export const isChromeBrowser = () => {
  const userAgent = globalThis.navigator?.userAgent;
  const isChromium = globalThis.chrome;
  const isChromeBrowser = userAgent.indexOf('Chrome') > -1;
  const isEdgeOrOperaSamsung =
    userAgent.indexOf('Edg') > -1 ||
    userAgent.indexOf('OPR') > -1 ||
    userAgent.indexOf('SamsungBrowser') > -1;

  return (
    isChromeBrowser && isChromium !== null && isChromium !== undefined && !isEdgeOrOperaSamsung
  );
};

export const isInAppBrowser = () => {
  const userAgent = navigator.userAgent || navigator?.vendor || window?.opera;

  const inAppPatterns = [
    /FBAN|FBAV/, // Facebook
    /Instagram/, // Instagram
    /Twitter/, // Twitter
    /Snapchat/, // Snapchat
    /TikTok/, // TikTok
    /WebView/, // Generic WebView
    /wv\)/, // Android WebView
    /Line/, // Line App
    /Pinterest/, // Pinterest
    /Discord/, // Discord
    /Weibo/, // Weibo
    /WeChat/, // WeChat
    /QQ/, // QQ
    /Slack/, // Slack
    /Reddit/, // Reddit
  ];

  return inAppPatterns.some((pattern) => pattern.test(userAgent));
};

const urlPatterns = [
  '/*/*/articles/*',
  '/wineries/*/wines/*',
  '/wineries/*',
  '/wines/*',
  '/wine-countries/*/promos/*',
  '/users/*/activities/*',
  '/users/*/reviews/*',
  '/users/*',
  '/toplists/*',
  '/wine-styles/*',
  '/checkout/vintages/*/prices/*/purchase_orders/new',
  '/checkout/*',
  '/carts',
  '/carts/*',
  '/explore',
  '/market',
  '/merchants/*',
  '/uni/*',
  '/*/w/*',
  '/premium/signup',
  '/premium/signup/*',
  '/premium/redeem',
  '/users/*/purchase_orders',
  '/users/*/purchase_orders/*',
  '/login_universal',
  '/wine-news',
  '/wine-news/*',
  '/adventures',
  '/adventures/*',
  '/cellar',
  '/users/*/rankings',
  '/mixitup',
  '/taste_profile',
  '/referral',
  '/articles/*',
  '/wine_club',
  '/wine_club/*',
  '/scanner/*',
  '/wine-regions/*',
  '/grapes/*',
  '/plus/management',
  '/last-scanned',
  '/last-rated',
  '/f/open-in-app/*',
  '/discover',
  '/dish/*',
];

export const matchesAppUrl = ({ path }) =>
  urlPatterns.map(patternToRegex).some((regex) => regex.test(path));

function patternToRegex(pattern) {
  const escapedPattern = pattern.replace(/[-/\\^$+?.()|[\]{}]/g, '\\$&');
  const regexPattern = escapedPattern.replace(/\*/g, '.*');
  return new RegExp(`^${regexPattern}$`);
}
