import { useBreakpoint } from '@context/DeviceBreakpointContext/DeviceBreakpointContext';
import { isInAppBrowser } from '@helpers/appBanner';
import {
  Button,
  ButtonTheme,
  Dialog,
  Typography,
  TypographyType,
} from '@vivino/js-react-common-ui';
import { track, useI18N } from '@vivino/js-web-common';
import React, { useEffect, useState } from 'react';

import VivinoIcon from '@components/icons/VivinoIcon/VivinoIcon';

import styles from './browserBanner.module.scss';

const TRANSLATIONS = {
  openInDefaultBrowser: 'common.open_in_browser',
  youAreUsingInAppBrowser: 'common.in_app_broswer',
  continueInDefaultBrowser: 'common.continue_in_default_browser',
};

const shouldShowBanner = () => {
  const dismissed = sessionStorage.getItem('browserBannerDismissed');
  return !dismissed;
};

const generateLink = () => {
  const isAndroid = globalThis.navigator?.userAgent?.match(/Android/i);
  const baseUrl = String(globalThis.location.host).replace('www.', '');
  return isAndroid
    ? `intent://${baseUrl}${globalThis.location.pathname}${globalThis.location.search}#Intent;scheme=https;end`
    : `x-safari-https://${baseUrl}${globalThis.location?.pathname}${globalThis.location?.search}`;
};

const BrowserBanner = ({ trackingScreen }: { trackingScreen?: string }) => {
  const [open, setOpen] = useState(shouldShowBanner);
  const { t } = useI18N();
  const { isDesktop } = useBreakpoint();

  useEffect(() => {
    if (!open) {
      sessionStorage.setItem('browserBannerDismissed', 'true');
    }
  }, [open]);

  const continueInDefaultBrowser = () => {
    track({
      screen: trackingScreen,
      event: 'InappBrowser - Clicked continue in default browser button',
    });
  };

  const closeBanner = () => {
    setOpen(false);
    track({ screen: trackingScreen, event: 'InappBrowser - Clicked close banner' });
  };

  if (!isInAppBrowser() || isDesktop || !open) {
    return null;
  }

  return (
    <Dialog
      dismissable
      dialogTitle={
        <div className={styles.header}>
          <div className={styles.logo}>
            <VivinoIcon />
          </div>
        </div>
      }
      open={open}
      onClose={closeBanner}
      componentsProps={{
        root: { 'aria-labelledby': 'browser-banner-title' },
      }}
    >
      <div className={styles.container}>
        <div>
          <Typography id="browser-banner-title" type={TypographyType.TitleExtraLarge}>
            {t(TRANSLATIONS.youAreUsingInAppBrowser)}
          </Typography>
        </div>
        <Typography type={TypographyType.BodyMedium}>
          {t(TRANSLATIONS.openInDefaultBrowser)}
        </Typography>
        <Button
          onClick={continueInDefaultBrowser}
          href={generateLink()}
          theme={ButtonTheme.Primary}
        >
          {t(TRANSLATIONS.continueInDefaultBrowser)}
        </Button>
      </div>
    </Dialog>
  );
};

export default BrowserBanner;
